<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader> <strong>All Suppliers </strong> </CCardHeader>
        <CCardBody>
          <div class="table-responsive">
            <table class="table table-hover table-sm">
              <thead>
                <tr>
                  <th scope="col" class="text-left">Name</th>
                  <th scope="col" class="text-left">Email</th>
                  <th scope="col" class="text-left">Contact no.</th>
                  <th scope="col" class="text-left">Company</th>
                  <th scope="col" class="text-left">Address</th>
                  <th
                    scope="col"
                    class="text-center"
                    v-if="
                      can('supplier_read') ||
                      can('supplier_update') ||
                      can('supplier_delete')
                    "
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody class="text-center pt-5" v-if="isLoading">
                <tr>
                  <td class="text-center" colspan="8">
                    <CSpinner color="primary" size="lg" />
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="supplier in suppliers" :key="supplier.id">
                  <td class="text-left">
                    {{ supplier.first_name + " " + supplier.last_name }}
                  </td>
                  <td class="text-left">{{ supplier.email }}</td>
                  <td class="text-left">{{ supplier.contact_no }}</td>
                  <td class="text-left">{{ supplier.company_name }}</td>
                  <td class="text-left">{{ supplier.address }}</td>

                  <td
                    class="text-center"
                    v-if="
                      can('supplier_read') ||
                      can('supplier_update') ||
                      can('supplier_delete')
                    "
                  >
                    <CButtonGroup size="sm">
                      <CButton
                        color="primary"
                        @click="showSupplier(showModal, supplier)"
                        v-if="can('supplier_read')"
                      >
                        View</CButton
                      >
                      <CButton
                        color="info"
                        @click="editSupplier(supplier.id)"
                        v-if="can('supplier_update')"
                      >
                        Edit</CButton
                      >
                      <CButton
                        @click="deleteSupplier(deleteModal, supplier)"
                        color="danger"
                        v-if="can('supplier_delete')"
                        >Delete</CButton
                      >
                    </CButtonGroup>
                  </td>
                </tr>
              </tbody>
            </table>
              <CPagination
              v-if="!isLoading"
              :activePage.sync="pagination.current"
              :pages="pagination.last"
              align="center"
              @update:activePage="getResults"
            />
          </div>
        </CCardBody>
      </CCard>
    </CCol>
    <SupplierView />
    <SupplierDelete />
  </CRow>
</template>

<script>
import { mapState } from "vuex";
const SupplierView = () => import("@/views/Supplier/SupplierView");
const SupplierDelete = () => import("@/views/Supplier/SupplierDelete");

export default {
  name: "Suppliers",

  components: {
    SupplierDelete,
    SupplierView,
  },

  created() {
    if (!this.$store.state.access_token) {
      this.$router.push({ name: "Login" });
    }
    this.$store.dispatch("suppliers/getSuppliers");
  },
  computed: {
    ...mapState({
      pagination: (state) => state.suppliers.pagination,
      suppliers: (state) => state.suppliers.suppliers,
      isLoading: (state) => state.suppliers.isLoading,
      deleteModal: (state) => state.suppliers.deleteModal,
      showModal: (state) => state.suppliers.showModal,
    }),
  },
  methods: {
    getResults(page = 1) {
      this.$store.dispatch("suppliers/getSuppliers", page);
    },
    can(permisson) {
      return this.$store.state.userPermissions
        ? this.$store.state.userPermissions.indexOf(permisson) >= 0
          ? true
          : false
        : null;
    },
    deleteSupplier(bool, supplier) {
      this.$store.commit("suppliers/DELETE_MODAL", {
        bool: bool,
        supplier: supplier,
      });
    },
    showSupplier(bool, supplier) {
      this.$store.commit("suppliers/SHOW_MODAL", {
        bool: bool,
        supplier: supplier,
      });
    },
    editSupplier(supplier_id) {
      this.$store.dispatch("suppliers/editSupplier", {
        supplier_id: supplier_id,
      });
      this.$router.push({ name: "Edit Supplier" });
    },
  },
};
</script>
